/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
import React, {useEffect, useState} from 'react';
import {gql, useMutation, useQuery} from '@apollo/client';
import {LoadingState, OnboardingInfoCard, OnboardingTermsCard,} from './components';
import {OnboardingPage} from '@shopify/channels-ui';
import {useNavigate} from 'react-router-dom';
import OnboardingEmailCard from "./components/OnboardingEmailCard";
import {Banner, FooterHelp, Icon, Link, Page} from "@shopify/polaris";
import {QuestionMarkInverseMinor} from "@shopify/polaris-icons";
import styled from "styled-components";

const ONBOARDING_PAGE_QUERY = gql`
  query OnboardingPageQuery {
    adminShop {
      id
      onboardingInfoCompleted
      termsAccepted
      onboardingCompleted
      partnerEmail
      invitationCompleted
    }
  }
`;

const COMPLETE_ONBOARDING_MUTATION = gql`
  mutation CompleteOnboarding {
    completeOnboarding {
      id
      onboardingCompleted
      invitationCompleted
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  padding: 20px 40px;
  border: 1px solid var(--p-surface-neutral);
  border-radius: 35px;
`;

const EmailBanner = ({partnerEmail, connected}) => {
  if (partnerEmail && !connected) {
    return (
      <Banner
        title="Account requirements"
        status="warning">
        Please finish registration! Until that your account will be disconnected.
        Check your email or finish onboarding in the browser window that was opened.
      </Banner>
    )
  } else {
    return <span></span>
  }
}

const ApproveBanner = ({partnerEmail}) => {
  if (partnerEmail) {
    return (
      <Banner
        title="Account approved"
        status="success">
        Your account has been approved!
      </Banner>
    )
  } else {
    return <span></span>
  }
}
const Onboarding = () => {
  const {data, loading} = useQuery(ONBOARDING_PAGE_QUERY);
  const [completeOnboarding, {loading: completeLoading}] = useMutation(
    COMPLETE_ONBOARDING_MUTATION,
  );
  const navigate = useNavigate();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (data && data.adminShop.onboardingCompleted) {
      navigate('/');
    }
  }, [data]);

  if (loading) {
    return <LoadingState/>;
  }

  const handleAction = () => {
    completeOnboarding();
  };

  const {
    adminShop: {
      onboardingInfoCompleted,
      termsAccepted,
      partnerEmail,
      invitationCompleted
    },
  } = data;

  return (
    <Page>
      <ApproveBanner partnerEmail={partnerEmail}/>
      <EmailBanner connected={connected || invitationCompleted} partnerEmail={partnerEmail}/>
      <OnboardingPage
        title="Setup KaiKai sales channel"
        breadcrumb={{
          content: 'Back',
          url: '/',
        }}
        action={{
          content: 'Finish',
          loading: completeLoading,
          disabled: !partnerEmail,
          onAction: handleAction,
        }}
      >
        <OnboardingInfoCard
          state={onboardingInfoCompleted ? 'completed' : 'active'}/>

        <OnboardingTermsCard
          state={termsAccepted ? 'completed' : onboardingInfoCompleted ? 'active' : 'disabled'}/>

        <OnboardingEmailCard
          state={partnerEmail ? 'completed' : termsAccepted ? 'active' : 'disabled'}
          partnerEmail={partnerEmail}
          setConnected={setConnected}/>
      </OnboardingPage>
      <FooterHelp>
        <Wrapper>
          <Icon source={QuestionMarkInverseMinor}/>
          <div>Learn more about{" "}</div>
          <Link url="https://partner.kaikai.ai/shopify-help/introduction" external>
            KaiKai
          </Link>
        </Wrapper>
      </FooterHelp>
    </Page>
  );
};

export default Onboarding;
