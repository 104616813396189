/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
import React, {useCallback, useState} from 'react';
import {OnboardingCard} from '@shopify/channels-ui';
import {gql, useMutation} from '@apollo/client';
import {Button, Stack, TextField} from "@shopify/polaris";

const ONBORDING_EMAIL_MUTATION = gql`
  mutation onboardingEmail($value: String!) {
    onboardingEmail(value: $value) {
      id
      partnerEmail
      invitationLink
      isRegistrationCompleted
    }
  }
`;

const OnboardingEmailCard = ({state, partnerEmail, setConnected}) => {
  const [onboardingEmail, {loading}] = useMutation(ONBORDING_EMAIL_MUTATION);

  const [email, setEmail] = useState(partnerEmail || '');

  const handleEmailChange = useCallback((value) => setEmail(value), []);

  const handleAction = () => {

    onboardingEmail(
      {
        variables: {
          value: email
        }
      }
    ).then(({data}) => {
      if (!data.onboardingEmail.isRegistrationCompleted) {
        window.open(data.onboardingEmail.invitationLink, "", "width=1000,height=800");


        window.onmessage = function ({data}) {
          if (data.type === 'portal') {
            setConnected(true)
          }
        };
      }
    })
  };

  return (
    <OnboardingCard
      title="Create KaiKai account"
      state={state}
      sectioned
    >
      {state === 'completed' ? (
        <p>You have registered with e-mail <strong>{email}</strong></p>
      ) : (
        <Stack vertical>
          <p>Buyers will be directed to checkout directly from the KaiKai</p>
          <TextField
            value={email}
            onChange={handleEmailChange}
            label="E-mail"
            type="email"
            autoComplete="email"
            helpText={
              <span>We’ll use this email address to setup Kaikai account</span>
            }
          />
          <Stack distribution="trailing">
            <Button
              primary
              disabled={!email}
              loading={loading}
              onClick={handleAction}
            >
              Register
            </Button>
          </Stack>
        </Stack>
      )}
    </OnboardingCard>
  );
};

export default OnboardingEmailCard;
