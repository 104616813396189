/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
import React from 'react';
import {gql, useQuery} from '@apollo/client';
import {Introduction, Overview} from './components';
import {Card, FooterHelp, Icon, Link, SkeletonBodyText, SkeletonPage} from '@shopify/polaris';
import {QuestionMarkInverseMinor} from "@shopify/polaris-icons";
import styled from "styled-components";

const HOME_PAGE_QUERY = gql`
  query HomePageQuery {
    adminShop {
      id
      onboardingInfoCompleted
      onboardingCompleted,
      accountConnected
      partnerEmail
      partnerId
      accountConnected
      invitationCompleted
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  padding: 20px 40px;
  border: 1px solid var(--p-surface-neutral);
  border-radius: 35px;
  margin-bottom: 30px;
`

const Home = () => {

  const {data, loading} = useQuery(HOME_PAGE_QUERY);
  if (loading) {
    return (
      <SkeletonPage>
        <Card sectioned>
          <SkeletonBodyText/>
        </Card>
      </SkeletonPage>
    );
  }

  const {
    adminShop: {
      onboardingInfoCompleted,
      onboardingCompleted,
      accountConnected,
      partnerEmail,
      partnerId,
      invitationCompleted
    },
  } = data;

  if (onboardingCompleted) {
    return (
      <div>
        <Overview
          accountConnected={accountConnected}
          partnerEmail={partnerEmail}
          partnerId={partnerId}
          invitationCompleted={invitationCompleted}
        />
        <FooterHelp>
          <Wrapper>
            <Icon source={QuestionMarkInverseMinor}/>
            <div>Learn more about{" "}</div>
            <Link url="https://partner.kaikai.ai/shopify-help/introduction" external>
              KaiKai
            </Link>
          </Wrapper>
        </FooterHelp>
      </div>
    );
  }

  return <Introduction setupInProgress={onboardingInfoCompleted}/>;
};

export default Home;
