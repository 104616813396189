/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
import * as React from 'react';
import {Card} from "@shopify/polaris";

const Commission = () => {
  return (<Card
    title="Commission and Transaction fees"
    sectioned>
    Commission and Transaction fees are set on a product level by our business team and can be seen in the Prices
    section of a product in KaiKai Portal. To have them set, please sign an agreement with Cosmose by contacting our
    support email.
  </Card>)
}

export default Commission;
