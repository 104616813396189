/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
import React, {useState} from 'react';
import {AccountConnection, Link} from "@shopify/polaris";
import {gql, useMutation} from "@apollo/client";

const CONNECT_ACCOUNT = gql`
  mutation connectAccount($value: Boolean!) {
    connectAccount(value: $value) {
      accountConnected
      id
    }
  }
`;

const Account = ({accountConnected, partnerEmail, partnerId, invitationCompleted}) => {
  const resolveButtonText = (connected) => {
    return connected ? 'Disconnect' : 'Connect';
  }

  const resolveDetails = (connected) => {
    return connected ? `Account ${partnerEmail} connected` : `Account ${partnerEmail} disconnected`;
  }

  const [connected, setAccountConnected] = useState(accountConnected);
  const [button, setButton] = useState(resolveButtonText(accountConnected));
  const [details, setDetails] = useState(resolveDetails(accountConnected));
  const [connectAccount, {loading}] = useMutation(
    CONNECT_ACCOUNT,
  );


  const handleAction = () => {
    if (!invitationCompleted) {
      return;
    }
    connectAccount({
      variables: {
        value: !connected
      }
    }).then(({data}) => {
      const {connectAccount} = data;
      setAccountConnected(connectAccount.accountConnected);
      setButton(resolveButtonText(connectAccount.accountConnected));
      setDetails(resolveDetails(connectAccount.accountConnected));
    })
  }

  return (
    <AccountConnection
      accountName={'accountName'}
      connected={connected}
      title="Kaikai account"
      action={{
        content: button,
        loading,
        onAction: handleAction,
      }}
      details={
        <>
          <Link url={`https://partner.kaikai.ai/partners/${partnerId}/products`} external>
            KaiKai Portal Account
          </Link>
          <div>
            {details}
          </div>
        </>
      }
    />
  )
}

export default Account;
