/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
import React from 'react';
import {useAppBridge} from '@shopify/app-bridge-react';
import {AppLink, ChannelMenu} from '@shopify/app-bridge/actions';
import Account from "./Account";
import Publishing from "./Publishing";
import Commission from "./Commission";
import styled from "styled-components";
import logo from "../../../assets/kaikai-new.svg";
import {Banner, List, Page} from "@shopify/polaris";


const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`

const Logo = styled.img`
  max-width: 300px;
`

const BannerWrapper = styled.div`
  margin-bottom: 1rem;
`

const Overview = ({
                    accountConnected,
                    partnerEmail,
                    partnerId,
                    invitationCompleted
                  }) => {
  const app = useAppBridge();
  const overviewLink = AppLink.create(app, {
    label: 'Overview',
    destination: '/',
  });
  const settingsLink = AppLink.create(app, {
    label: 'Settings',
    destination: '/settings',
  });
  ChannelMenu.create(app, {
    items: [overviewLink, settingsLink],
    active: overviewLink,
  });

  return (
    <Page>
      <BannerWrapper>
        <Banner
          title="Eligibility requirements"
          status="warning">
          <List>
            <List.Item>Your business needs to be available in Singapore or Hong Kong (soon the USA and
              Japan).</List.Item>
            <List.Item>You need to sell products in physical locations</List.Item>
            <List.Item>You need to sign an agreement with the KaiKai business team.</List.Item>
          </List>
        </Banner>
        {
          !invitationCompleted && <Banner
            title="Account requirements"
            status="warning">
            <div>
              <div>Please finish registration! Until that your account will be <strong>disconnected.</strong></div>
              <div>Connect button won't perform any action.</div>
              <div>Check your email or finish onboarding in the browser window that was opened.</div>
            </div>
          </Banner>
        }
      </BannerWrapper>
      <LogoWrapper>
        <Logo src={logo}/>
      </LogoWrapper>
      <Account
        accountConnected={accountConnected}
        partnerEmail={partnerEmail}
        invitationCompleted={invitationCompleted}
        partnerId={partnerId}/>
      <Publishing/>
      <Commission/>
    </Page>
  );
};

export default Overview;
