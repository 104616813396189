/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
import * as React from "react";
import {gql, useQuery} from "@apollo/client";
import {Badge, Banner, Card, Icon, Link, List, SkeletonBodyText} from "@shopify/polaris";
import styled from "styled-components";
import {CircleAlertMajor} from "@shopify/polaris-icons";

const PUBLISHING_QUERY = gql`
  query PublishingQuery {
    adminShop {
      id
      domain
      appHandle
      publicationId
      availableProductCount
      publishedProductCount
    }
    productsListening {
      productId
      title
      feedback {
        messages
      }
    }
  }
`;

const Wrapper = styled.div`
  margin: 10px 0 20px;
`

const IconWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
`

const Description = styled.div`
  display: flex;
  gap: 5px;
`

const BadgeWrapper = styled.div`
  margin-top: 10px;
`
const LinkWrapper = styled.span`
  margin-left: 10px;
`

const ProductWrapper = styled.div`
  margin: 20px 0;
`

const Publishing = () => {
  const {data, loading} = useQuery(PUBLISHING_QUERY);
  if (loading) {
    return (
      <Card sectioned>
        <SkeletonBodyText/>
      </Card>
    );
  }

  const {
    adminShop: {
      domain,
      appHandle,
      publicationId,
      availableProductCount,
      publishedProductCount,
    },
    productsListening
  } = data;
  const notPublished = availableProductCount - publishedProductCount;

  const productsWithError = productsListening.filter((product) => product.feedback.some((feedback) => feedback.messages.length))
  const publishedWithNoErrors = publishedProductCount - productsWithError.length;

  return (
    <Card
      title="Publishing status"
      actions={{
        content: 'Manage availability',
        loading,
        url: `https://${domain}/admin/bulk?resource_name=Product&edit=publications.${publicationId}.published_at`,
        external: true
      }}>
      <Card.Section>
        {productsWithError.length > 0 &&
          <Wrapper>
            <Banner
              status="critical">
              <p><strong>{productsWithError.length} products</strong> require more information before they can be
                published on Marketplace</p>

              {productsWithError.map((product) =>
                <ProductWrapper key={product.productId}>
                  <Link url={`https://${domain}/admin/products/${product.productId}`} external>
                    <strong>Product: {product.title}</strong>
                  </Link>
                  <List>
                    {product.feedback.map((feedback) =>
                      <>
                        {feedback.messages.map(message => (
                          <List.Item key={message}>{message}</List.Item>
                        ))}
                      </>
                    )}
                  </List>
                </ProductWrapper>
              )}
            </Banner>
          </Wrapper>}

        <Description>
          <p><strong>{availableProductCount}</strong> products available to KaiKai</p>
          <Link url={`https://${domain}/admin/products?selectedView=all&published_status=${appHandle}%3Avisible`}
                external>
            Available products.
          </Link>
        </Description>

        {publishedWithNoErrors > 0 && <BadgeWrapper>
          <Badge
            status="success"
            progress="complete"
            statusAndProgressLabelOverride="Status: Published. Your online store is visible."
          >
            Published
          </Badge>
          <LinkWrapper>
            {publishedWithNoErrors} products
          </LinkWrapper>
        </BadgeWrapper>}

        <BadgeWrapper>
          <Badge
            status="success"
            progress="incomplete"
            statusAndProgressLabelOverride="Status: Synchronized."
          >
            Synchronized
          </Badge>
          <LinkWrapper>
            {publishedProductCount} products
          </LinkWrapper>
        </BadgeWrapper>

        {productsWithError.length > 0 && <BadgeWrapper>
          <Badge
            status="critical"
            progress="incomplete"
            statusAndProgressLabelOverride="Status: With error."
          >
            With errors
          </Badge>
          <LinkWrapper>
            {productsWithError.length} products
          </LinkWrapper>
        </BadgeWrapper>}

        {notPublished > 0 &&
          <BadgeWrapper>
            <Badge
              status="warning"
              progress="incomplete"
              statusAndProgressLabelOverride="Status: Published. Your online store is visible."
            >
              Not published
            </Badge>
            <LinkWrapper>
              {notPublished} products
            </LinkWrapper>
          </BadgeWrapper>
        }
      </Card.Section>
      <Card.Section>
        <IconWrapper>
          <Icon source={CircleAlertMajor}/>
          <p>Product publishing to KaiKai can take 10 minutes to update.</p>
        </IconWrapper>
      </Card.Section>
    </Card>
  )
}

export default Publishing;
