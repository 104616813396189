/**
 * :cow: Cosmose CONFIDENTIAL :iso:
 */
import {FeatureCard, IntroductionPage} from '@shopify/channels-ui';
import Logo from './images/logo.svg';
import React from 'react';
import {useAppBridge} from '@shopify/app-bridge-react';
import {ChannelMenu} from '@shopify/app-bridge/actions';
import styled from "styled-components";
import {Banner, FooterHelp, Icon, Link, Page} from "@shopify/polaris";
import {QuestionMarkInverseMinor} from "@shopify/polaris-icons";

const Wrapper = styled.div`
  position: relative;
  background: linear-gradient(38.9deg, #DBFAED 15.63%, #43B38E 76.62%);
  padding: 5%;
  display: grid;
  grid-template-areas: 'image';
  overflow: hidden;
`

const FooterWrapper = styled.div`
  display: flex;
  gap: 5px;
  padding: 20px 40px;
  border: 1px solid var(--p-surface-neutral);
  border-radius: 35px;
`

const Introduction = ({setupInProgress}) => {
  const app = useAppBridge();
  ChannelMenu.create(app, {
    items: [],
  });

  return (
    <Page>
      <Banner
        title="The KaiKai sales channel is currently available only in Singapore and Hong Kong."
        status="warning">
      </Banner>

      <IntroductionPage title="Get started with the KaiKai channel">
        <FeatureCard
          title="Sell your products on KaiKai"
          description="Let customers discover your brand and purchase your products directly on the KaiKai."
          feature="KaiKai"
          badgeText="Free"
          primaryAction={{
            content: setupInProgress ? 'Continue setup' : 'Start setup',
            url: '/onboarding',
          }}
          portrait
        >
          <Wrapper>
            <img
              style={{
                gridArea: 'image',
                alignSelf: 'start',
                justifySelf: 'start',
              }}
              src={Logo}
            />
          </Wrapper>
        </FeatureCard>
      </IntroductionPage>
      <FooterHelp>
        <FooterWrapper>
          <Icon source={QuestionMarkInverseMinor}/>
          <div>Learn more about{" "}</div>
          <Link url="https://partner.kaikai.ai/shopify-help/introduction" external>
            KaiKai
          </Link>
        </FooterWrapper>
      </FooterHelp>
    </Page>
  );
};

export default Introduction;
